/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import { ApolloProvider } from '@apollo/client';
import { client } from './src/utils/apollo-client';

import './src/scss/app.scss'
import { ThemeProvider } from "./src/context/ThemeContext"

export const wrapRootElement = (data) => {
    return (
        <ApolloProvider client={client}>{data.element}</ApolloProvider>
    )
}
