import fetch from 'cross-fetch'
import { createPrismicLink } from "apollo-link-prismic"
import { ApolloClient, InMemoryCache } from '@apollo/client'


export const client = new ApolloClient({
    link: createPrismicLink({
        repositoryName: "meniga",
        fetch: fetch,
    }),
    cache: new InMemoryCache()
})