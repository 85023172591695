exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-claim-js": () => import("./../../../src/pages/claim.js" /* webpackChunkName: "component---src-pages-claim-js" */),
  "component---src-pages-your-solutions-js": () => import("./../../../src/pages/your-solutions.js" /* webpackChunkName: "component---src-pages-your-solutions-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-demo-js": () => import("./../../../src/templates/demo.js" /* webpackChunkName: "component---src-templates-demo-js" */),
  "component---src-templates-demo-thanks-js": () => import("./../../../src/templates/demo-thanks.js" /* webpackChunkName: "component---src-templates-demo-thanks-js" */),
  "component---src-templates-download-js": () => import("./../../../src/templates/download.js" /* webpackChunkName: "component---src-templates-download-js" */),
  "component---src-templates-download-thanks-js": () => import("./../../../src/templates/download-thanks.js" /* webpackChunkName: "component---src-templates-download-thanks-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-v-2-js": () => import("./../../../src/templates/page-v2.js" /* webpackChunkName: "component---src-templates-page-v-2-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

